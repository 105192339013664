// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

var $ = require('jquery');
var bootstrap = require('../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js');
var slick = require('../../node_modules/slick-carousel/slick/slick.min.js');
var Link = require('../_modules/link/link');

$(function() {
  new Link(); // Activate Link modules logic
  console.log('Welcome to Yeogurt!');

  $('.section-1__slider').slick();
});
